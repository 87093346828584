/*
* @Author: XL
* @Description: 词汇辨析
* @ModuleName: 词汇辨析
* @Date: 2018-09-25 18:16:49
* @Last Modified by: XL
* @Last Modified time: 2020-03-03 17:12:16
*/
<style lang="less" scoped>
    .question_des {

        .Pr_title {
            font-size: 20px;
            letter-spacing: 0px;
            color: #cccccc;
            text-align: center;
            margin-bottom: 30px;
        }

        .Pr_img {
            display: block;
            margin: 0 auto;
            height: 2.4rem;
        }
    }
</style>

<template>
        <div class="T_box">
            <Head :Target='Target' :RightLeft='RightLeft' @show='pshow=true' :UpLayerData='UpLayerData' />
            <AudioPlayer ref="HintAudioPlayer"  :theUrl="HintAudioPlayerUrl" @audioEndedOver="HintPlayerComplete" :config="HintAudioPlayerConfig" />
            <div class="T_content">
                <div class="Unit_d">
                    <div class="Unit_con">
                        <div class="Second_title">
                            <div class="Audio_play">
                                <span class="t1" style="font-weight:bold;font-style:italic;">NO.
                                    {{questionIndex + 1}}/{{questionAll}}</span>
                                <div></div>
                                <div class="describe" @click="pshow = !pshow"  v-if="Pattern!='测试模式'">
                                    <img :src=" require( '@/assets/shuoming.png')" alt="">
                                    <span>Directions</span>
                                </div>
                            </div>
                        </div>
                        <div class="Question_box clearfix">
                            <div :class="['question_des',(threeActive=='')?'active':'']">
                                <div :class="['content_t',complate?'complate':'']" >
                                    <div class="words">
                                        <div class="title2">
                                            <span v-html="ReplacePhoneticSymbols(EnglishText)">{{EnglishText}}</span>
                                            <AudioPlayer ref="stemAudioPlayer" v-show="AudioPlayerUrl"  :theUrl="AudioPlayerUrl" @audioEndedOver="PlayerComplete" :config="AudioPlayerConfig" />
                                            <span class="divide"></span>
                                            <img :src="favpic" class="Favorites" @click="fav()"></img>
                                        </div>
                                        <div class="title3">
                                            <span v-if="ChineseText !='null'">{{ChineseText}}</span>
                                        </div>
                                    </div>
                                    <div :class="['Soption_box',(threeActive=='NOTES'||threeActive=='EMPHASIS'||threeActive=='TEXT'||threeActive=='KEY')?'Soption_box_Active':'']">
                                        <div class="option_left">  
                                            <div class="_img">
                                                <img :src="getPic()" class="wordsimg"/>
                                            </div>
                                        </div>
                                        <div class="option_right">
                                            <div class="content2">
                                                <div class="content2_title"><span ></span>Synonym</div>
                                                <div v-if="Syn.length == 0"></div>
                                                <div v-else class="min_height">
                                                    <div v-for='(item,index) in Syn' :key='index' class="syn">
                                                        <div v-show="item.englishText">
                                                            <span class="Homoinym-englishText">{{index + 1}}、{{item.englishText}}</span>  
                                                            <AudioPlayer :ref="'AudioPlayerUrl2' + index "  :theUrl="AudioPlayerUrl2[index].url" @audioEndedOver="PlayerComplete2(index)" :config="{autoplay:AudioPlayerUrl2[index].isPyle,theSpeeds:[1],}" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>

                            <div class="Text" v-show="threeActive=='TEXT'">
                                <div class="title">Chinese<i class="el-icon-close right" @click="threeShowClose"></i></div>
                                <div style="height:100%;">
                                    <div class="t_content" style="border:none">
                                        <div v-if="!!ChineseText&&ChineseText!='null'&&ChineseText!=''" v-html="ChineseText"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="Notes" v-show="threeActive=='NOTES'">
                                <div class="title"> Notes  <i class="el-icon-close right" @click="threeShowClose"></i> </div>
                                <div class="t_content">
                                    <textarea name="" v-model="NoteItem.content" @focus="NoteFocus()" @blur="NoteBlur()" maxlength="500" id="" cols="30" rows="10"></textarea>
                                    <img v-if="NoteItem.content.length<=0" src="../../assets/notesbc.png" class="bc" alt="">
                                    <button class="save" @click="SaveNotes"> Save </button>
                                </div>
                            </div>
                            <!-- key -->
                            <div class="Text" v-show="threeActive=='KEY'">
                                <div class="title">Key <i class="el-icon-close right" @click="threeShowClose"></i></div>
                                <div class="t_content" v-html="AnswerKey"></div>
                            </div>
                            <div class="Notes" v-show="threeActive=='EMPHASIS'">
                                <div class="title">Emphasis<i class="el-icon-close right" @click="threeShowClose"></i> </div>
                                <div class="t_content">
                                    <div class="words3" v-if="Ext.length">
                                        <span>{{Ext[ExtIndex].englishText}}</span>
                                        <AudioPlayer ref="EmphasisAudioPlayer" v-show="Pattern!='测试模式'" :theUrl="Ext[ExtIndex].englishAudio"
                                                     v-if="Ext[ExtIndex].englishAudio != null && Ext[ExtIndex].englishAudio != '' && Ext[ExtIndex].englishAudio != undefined"
                                                      :config="EmphasisAudioPlayerConfig" />
                                    </div>
                                    <div v-if="Ext.length">
                                        <img :src="getPic2()" v-if="getPic2() != null" class="wordsimg2"></img>
                                    </div>
                                    <div class="words2" v-if="Ext.length">
                                        <span>{{Ext[ExtIndex].englishTextStatement}}</span>
                                    </div>
                                    <div class="gotoBtnBox"  v-if="Ext.length>0">
                                        <div class="gotoBtn1"  @click="gotoL()"></div>
                                        <div class="btn-number">{{ExtIndex + 1}}/{{Ext.length}}</div>
                                        <div class="gotoBtn2"  @click="gotoN()"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="T_option">
                    <div class="Unit_option">
                        <div class="btn_list">
                            <span class="btn"  @click="On_back()"></span>
                            <span class="btn2" v-if="Pattern!='测试模式'" @click="On_next()"></span>

                            <div class="Record_btn" v-if="RecordeItem.show">
                                <img src="../../assets/Microphone.png" class="T_1" alt="" @click="Record_a('1')">

                                <img src="../../assets/play_bai.png" v-if="Pattern!='测试模式' && !RecordeItem.MediaInPlay && recorderPlayerUrl && !RecordBtn"
                                     class="T_2" alt="" @click='MediaPlay()'>
                                <img src="../../assets/pause.png" v-if="Pattern!='测试模式' && RecordeItem.MediaInPlay && recorderPlayerUrl && !RecordBtn"
                                     class="T_2" alt="" @click='MediaPlay()'> 
                                <div class="Re_conm" v-if='RecordBtn'>
                                    <span class="horn"></span>
                                    <div class="Re_box">
                                        <div id="Wave">
                                            <div v-for="i in 10" class="WaveItem" :key="i"
                                                 :style="'height:'+ Wheight(i) +'px'">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="countdown"> {{RecordeItem.timeshow}} </div>
                                </div>
                            </div>
                            <Recorder ref="Recorder" @upRcData="AddAudio"></Recorder>
                            <AudioPlayer ref="recorderPlayer" @audioEndedOver="recorderPlayerAudioEndedOver" :theUrl="recorderPlayerUrl"  :config="recorderPlayerConfig" />
                            <div class="btn_cover" v-if='RecordBtn'></div> 
                        </div>
                        <Time :Pattern='Pattern' />
                    </div>
                </div>
            </div>

            <Search v-if="this.$store.state.common.searchActive"></Search>
            <Words v-if="this.$store.state.common.wordsActive" @childMsg="childMsg"></Words>
            <popup :show.sync="pshow" :describe='this.Target.description'></popup>
            <div class="Re_cover" v-if='RecordBtn'></div>

        </div>

</template>

<script>
    import popup from '../describe/Popup.vue'
    import Head from '../../components/head.vue'
    import "video.js/dist/video-js.min.css"
    import Search from "./Search"
    import Words from "./Words"
    import { ReplacePhoneticSymbols } from "@/utils/commonFn.js"

    export default {
        data() {
            return {
                ReplacePhoneticSymbols:ReplacePhoneticSymbols,
                favpic: require('../../assets/Favorites1.png') ,
                mshow: {
                    text: 1,
                    notes: 1,
                    key: 1,
                    // 0 不可选 1 可选 2 已选
                },
                Panduan: true,
                Target: {
                    catalog: '',
                    description: '',
                    isEnd: null,
                    title: '',
                },
                RightLeft: false,
                pshow: false,
                questionData: {},
                childQuestionData: {},
                questionIndex: 0,
                questionAll: 0,
                childQuestionListIndex: 0, // 当前子题
                childQuestionListAll: 0, // 所有子题 长度
                AnswerKey: '',

                // 播放器 参数  命名 不要重复   mixin 中使用了 参数
                GetTime: false, // 获取 视频总时长
                audioTime: 0, // 滑块 value
                player: null, // 播放器
                playertime: '', //总时长
                InPlay: false, // 判断是否 播放中
                // 播放器 参数

                check: '',
                complate: false,
                answer: '',

                NoteItem: {
                    id: '',
                    content: '',
                },
                // 绑题 规则
                BindRole: {},


                // 树形 上层数据
                UpLayerData: {},

                InfoComplate: false, // 首次加载数据判断


                // 录音
                RecordeItem: {
                    show: true,
                    time: null,
                    timeshow: '',
                    Count: null,
                    begin: false,
                    getAudio: null,
                    RecorderAudio: 0, //  判断  当前 音频为录音  或  已录音音频    0 未录音   1 已录音在线音频   2本地录制音频
                    Media: null, // 录音播放 多媒体
                    MediaInPlay: false, // 录音播放状态
                },

                // 波浪纹  动画
                wave: null,
                waveIndex: 0,

                // 提示音
                Hint: null,

                ProgressBar: {
                    show: false,
                    width: 100,
                    time: null,
                    duration: 0,
                    timeing: 0,
                },
                AudioPlayerUrl:"",//题干音频地址
                AudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[1],
                },
                EmphasisAudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[1],
                },
                AudioPlayerUrl2:[],//扩展音频地址
                AudioPlayerConfig2:{
                    autoplay:false,
                    theSpeeds:[1],
                },
                HintAudioPlayerUrl:"",//提示音频地址
                HintAudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[],
                },
                recorderPlayerUrl:"",//录音音频地址
                recorderPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[],
                },
                EnglishText:'',
                ChineseText:'',
                Ext: null,
                Syn: [],
                ExtIndex: 0
            }
        },
        components: {
            popup,
            Head,
            Search,
            Words
        },
        created() {
            this.$store.commit('setitem',{'name':'searchActive','item':false});
            this.$store.commit('setitem',{'name':'wordsActive','item':false});
            if (this.menulist.length > 0) {
                this.Panduan = true;
                this.getinfo();
            } else {
                this.Panduan = false;
            }
        },
        mounted() {
        },
        computed: {
            datacomplate: function () {
                return this.$store.state.common.datacomplate;
            },
            checkid: function () {
                return this.$store.state.common.checkid;
            },
            RecordBtn: function () {
                return this.$store.state.common.RecordBtn;
            },
            testPause: function () {
                return this.$store.state.common.testPause;
            },
        },
        watch: {
            datacomplate: function (v) {
                if (!this.Panduan) {
                    this.getinfo();
                }
            },
            checkid: function () {
                if (this.InfoComplate) {
                    this.getinfo();
                }
            },
            testPause: function (v, oldv) {
                if (this.Pattern == '测试模式') {
                    if (v && !oldv && !this.complate) { // 开始
                        if (!!this.QuestionChange) {
                            this.QuestionChange();
                        } else {
                            if (!!this.pauseBegin) this.pauseBegin();
                        }
                    }
                    if (oldv && !v) { // 暂停
                        if (!!this.stayPause) this.stayPause();
                    }
                }
            },
            threeActive: function (v) {
                if (v == 'KEY' && !!this.CheckKey) {
                    this.CheckKey();
                }
            },
        },
        methods: {
            childMsg (data) {
                console.log(data);
                this.checkFav()
            },
            async fav() {

                let layer = sessionStorage.getItem('targetceng').split(',');
                layer = layer.map(el => {
                    el = el.split('.');
                    return el;
                })

                let courceId = this.menulist[layer[0][1]].courceId
                console.log(courceId)
                let qIndex = sessionStorage.getItem('questionIndex') || 0;
                let questionId = this.questionData.childQuestionList[qIndex].questionId
                let sectionId = this.Target.id
                let sectionName = this.Target.title

                try{
                    let trainClassId = this.$route.query.trainClassId
                    let data = await this.AjaxApi.checkFav(courceId, questionId, sectionId, trainClassId);
                    console.log(data)
                    if (data.code === '200' && data.data !== null) {
                        let ids = data.data.id

                        let data2 = await this.AjaxApi.deleteData(ids)
                        if (data2 === false) {
                            this.favpic = require('../../assets/Favorites2.png')
                            this.$message.error('取消收藏失败!');
                            return
                        } else {
                            this.favpic = require('../../assets/Favorites1.png')
                            this.$message.success('取消收藏成功!');
                        }
                    }
                    if (data.code === '200' && data.data === null) {
                        let data = await this.AjaxApi.setFav(courceId, questionId, sectionId, sectionName, trainClassId);

                        if (data === false) {
                            this.favpic = require('../../assets/Favorites1.png')
                            this.$message.error('收藏失败!');
                        } else {
                            this.favpic = require('../../assets/Favorites2.png')
                            this.$message.success('收藏成功!');
                        }
                    }
                }catch(e){
                    this.favpic = require('../../assets/Favorites1.png')
                    this.$message.error('收藏失败!');
                }
            },
            async checkFav() {

                let layer = sessionStorage.getItem('targetceng').split(',');
                layer = layer.map(el => {
                    el = el.split('.');
                    return el;
                })

                let courceId = this.menulist[layer[0][1]].courceId
                console.log(courceId)
                let qIndex = sessionStorage.getItem('questionIndex') || 0;
                let questionId = this.questionData.childQuestionList[qIndex].questionId
                let sectionId = this.Target.id

                try{
                    let trainClassId = this.$route.query.trainClassId
                    let data = await this.AjaxApi.checkFav(courceId, questionId, sectionId, trainClassId);
                    console.log(data)
                    if (data.code === '200' && data.data !== null) {
                        if (data.data.questionId === questionId) {
                            this.favpic = require('../../assets/Favorites2.png')
                        }
                    }
                    if (data.code === '200' && data.data === null) {
                        this.favpic = require('../../assets/Favorites1.png')
                    }
                }catch(e){
                    this.$message.error('检查收藏失败!');
                }
            },
            gotoN() {
                if (this.ExtIndex === this.Ext.length - 1)  return
                if (this.Ext.length ==  0 )  return
                this.ExtIndex++
            },
            gotoL() {
                if (this.ExtIndex === 0)
                    return
                this.ExtIndex--
            },
            getPic() {
                let qIndex = sessionStorage.getItem('questionIndex') || 0;

                if (this.questionData.childQuestionList[qIndex].wordsScenePictureResource === null
                    || this.questionData.childQuestionList[qIndex].wordsScenePictureResource === undefined
                    || this.questionData.childQuestionList[qIndex].wordsScenePictureResource === '')
                    return  null

                let url1 = this.$store.state.common.AudioUrlPublic + '/Images/' + this.questionData.childQuestionList[qIndex].wordsScenePictureResource
                console.log(url1)
                return url1
            },
            getPic2() {
                if (this.Ext[this.ExtIndex].sceneImages === null
                    || this.Ext[this.ExtIndex].sceneImages === undefined
                    || this.Ext[this.ExtIndex].sceneImages === '')
                    return  null
                let url1 = this.$store.state.common.AudioUrlPublic + this.Ext[this.ExtIndex].sceneImages
                console.log(url1)
                return url1
            },
            getAudio2() {
                let qIndex = sessionStorage.getItem('questionIndex') || 0;
                let url1 = this.questionData.childQuestionList[qIndex].englishAudioResource

                if (url1 === null
                    || url1 === undefined
                    || url1 === '')
                    return null
                console.log(url1)
                return url1
            },
            getAudio3(index) {
                if(this.Syn.length>0){
                    let url1 = this.Syn[index].englishAudio
    
                    if (url1 === null
                        || url1 === undefined
                        || url1 === '')
                        return null
                    console.log(url1)
                    return url1

                }
            },
            stayPause() { // 测试模式暂停
                if (this.complate) {
                    this.$message.warning('您本题已提交答案,无法暂停!');
                    this.$store.commit('setitem', {
                        name: 'testPause',
                        item: true
                    });
                    return false;
                }
                this.InPlay = false;
                if (!!this.player) this.player.pause();
                if (!!this.Hint) this.Hint.pause();
                if (!!this.twicePlayTimeOut) clearTimeout(this.twicePlayTimeOut)
                // 录音播放停止
                if (this.RecordeItem.MediaInPlay) {
                    if (this.RecordeItem.RecorderAudio == 1) {
                        this.RecordeItem.Media.pause();
                    }
                    this.RecordeItem.MediaInPlay = false;
                }
                if (this.RecordBtn) {
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    Recorder.stop();
                    this.$store.commit('setitem', {
                        'name': 'RecordBtn',
                        'item': false
                    })
                }
            },
            Wheight(i) {
                let t = i - 1;
                let c = Math.abs(Math.abs((Math.floor(t / 5) * 5 - t % 5)) * 40 - 300) - 30;
                let d = 300 * 300 - c * c;
                d = Math.sqrt(d) * 2;
                return d;
            },

            async getinfo() {
                this.RecordeItem.Media = document.createElement('audio');
                let data = this.GetTargetItem();
                this.UpLayerData = data.updata; // 上 层数据
                if (!!data.last) {
                    this.Target = data.data[data.last];
                } else {
                    this.Target = data.data;
                }
                this.Target.title = data.title;
                // 判断 学习模式
                this.$store.commit('setitem', {
                    'name': 'Pattern',
                    'item': this.Target.bindRole.ruleTypeName
                });
                this.$store.commit('setitem', {
                    'name': 'RoleIsJudgment',
                    'item': this.Target.bindRole.isJudgment
                });

                // 判断 绑题规则 设置 notes key txt
                this.BindRole = this.Target.bindRole;
                this.threeShow(this.BindRole);
                if (this.BindRole.isRecordNote == 1) this.mshow.notes = 1;
                else this.mshow.notes = 0;

                if (this.BindRole.isReadAnswer == 1) this.mshow.key = 1;
                else this.mshow.key = 0;

                if (this.BindRole.isReadOriginal == 1) this.mshow.text = 1;
                else this.mshow.text = 0;
                // 判断 绑题规则 设置 notes key txt
                if (this.Target.isEnd == 1 && data.data.length > 1) {
                    this.RightLeft = true;
                } else {
                    this.RightLeft = false;
                }

                let qIndex = sessionStorage.getItem('questionIndex') || 0; // 问题下标
                this.questionIndex = sessionStorage.getItem('SonquestionIndex') || 0; // 问题子题下标
                this.questionAll = this.Target.questionList.length; // 问题 总数
                let questiondata = await this.GetQuestion(this.Target.questionList[qIndex].oldId, this.Target
                    .sectionMain.id);
                this.questionData = questiondata.data; // 当前问题 数据

                if (this.questionData.childQuestionList != undefined && this.questionData.childQuestionList[qIndex].englishText != undefined && this.questionData.childQuestionList[qIndex].englishText != '') {
                    this.EnglishText = this.questionData.childQuestionList[qIndex].englishText
                }
                if (this.questionData.childQuestionList != undefined && this.questionData.childQuestionList[qIndex].chineseText != undefined && this.questionData.childQuestionList[qIndex].chineseText != '') {
                    this.ChineseText = this.questionData.childQuestionList[qIndex].chineseText
                }

                //Extensions
                let ext
                if (this.questionData.childQuestionList != undefined && this.questionData.childQuestionList[qIndex].extensions != undefined && this.questionData.childQuestionList[qIndex].extensions != '') {
                    ext = this.questionData.childQuestionList[qIndex].extensions
                }

                 let arr = eval('('+ext+')');
                this.Ext = arr.filter(el=>{
                    return el.englishText
                })

                //Synonyms
                let syn
                if (this.questionData.childQuestionList != undefined && this.questionData.childQuestionList[qIndex].synonyms != undefined && this.questionData.childQuestionList[qIndex].synonyms != '') {
                    syn = this.questionData.childQuestionList[qIndex].synonyms
                }

                this.Syn=eval(syn);
                this.Syn = this.Syn.filter((item)=>{
                    return item.englishText
                })
                console.log(this.Syn, '----------------')

                this.checkFav()

                this.childQuestionData = this.questionData.childQuestionList[qIndex];
                if (!!this.questionData.questionText && this.questionData.questionText != 'null' && this
                    .questionData.questionText != '') {
                    this.questionData.questionText = this.questionData.questionText.replace(this.reg, '');
                    this.questionData.Texta =
                        `<span>Subject text</span><div>${this.questionData.questionText}</div>`;
                } else {
                    this.questionData.Texta = '';
                }

                let aaaa = this.questionData

                // 录音查询
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                this.RecordeItem.MediaInPlay = false;
                if (!!record_.data && !!record_.data.filePath) {
                    // 赋值录音路径
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = '1';
                }else{
                    this.recorderPlayerUrl = null
                }
                if(this.questionData.childQuestionList == null ){
                    this.childQuestionListAll = 1; // 当前问题 子题总数
                }else{
                    this.childQuestionListAll = this.questionData.childQuestionList.length; // 当前问题 子题总数
                    console.log(this.childQuestionListAll)
                }

                // 赋值音频路径
                this.AudioPlayerUrl = this.questionData.childQuestionList[0].englishAudioResource
                if(this.BindRole.isAutoPaly == 1){
                    this.AudioPlayerConfig.autoplay = true
                    this.InPlay = true
                }else{
                    this.AudioPlayerConfig.autoplay = false
                }
                if(Array.isArray(this.Syn) && this.Syn.length>0){
                    this.Syn = this.Syn.filter((item)=>{
                        return item.englishText
                    })
                    this.AudioPlayerUrl2 = []
                    this.Syn.map(el=>{
                        if(el.englishAudio){
                            this.AudioPlayerUrl2.push({url:el.englishAudio,isPyle:false})
                        }else{
                            this.AudioPlayerUrl2.push({url:'notEnglishAudio',isPyle:false})
                        }
                    })
                }

                // this.AudioPlayerUrl = this.questionData.textResource
                // console.log(this.AudioPlayerUrl ,'this.AudioPlayerUrl ');
                // if(this.BindRole.isAutoPaly == 1){
                //     this.AudioPlayerConfig.autoplay = true
                //     this.InPlay = true
                // }else{
                //     this.AudioPlayerConfig.autoplay = false
                // }
                this.InfoComplate = true;
            },
            async CheckKey() {
                let url_ = await this.AjaxApi.getAnswerById(this.questionData.childQuestionList[this
                    .childQuestionListIndex].id);
                let answerKey = '';
                if (!!url_.data.answers && url_.data.answers != null) answerKey =
                    `<span>Answer</span><div>${url_.data.answers}</div> `;
                if (!!url_.data.answerKey && url_.data.answerKey != null) answerKey +=
                    `<span>Keyword</span><div>${url_.data.answerKey}</div>`;
                this.AnswerKey = answerKey;
                this.mshow.key = 2;
                if (this.mshow.notes != 0) this.mshow.notes = 1;
                if (this.mshow.text != 0) this.mshow.text = 1;
            },
            // 保存 笔记
            async SaveNotes() {
                let question = this.questionData.childQuestionList[this.childQuestionListIndex];
                let item = this.NoteItem;
                item.content = item.content.replace(/(^\s*)|(\s*$)/g, '');
                if (!!!item.content) {
                    this.$message.warning('请填写笔记!');
                    return false;
                }

                let layer = sessionStorage.getItem('targetceng').split(',');
                layer = layer.map(el => {
                    el = el.split('.');
                    return el;
                })
                let data = {
                    courseId: this.menulist[layer[0][1]].courceId,
                    nodeId: this.Target.id,
                    title: '',
                    content: item.content,
                    questionId: question.id,
                    levelName: this.Target.catalog,
                }

                if (!!item.id) {
                    let edit_ = await this.AjaxApi.updateCourseNote(item);
                    if (edit_.code == '200') {
                        this.$message.success('修改笔记成功!');
                    }
                } else {
                    let add_ = await this.AjaxApi.addCourseAdvice(data);
                    if (add_.code == '200') {
                        this.$message.success('添加笔记成功!');
                        this.NoteItem.id = add_.data.id;
                    }
                }

            },


            // 录音  控制
            Record_a(it) {
                if (it == '1') {
                    if (this.Pattern == '测试模式') {
                        return;
                    }
                }
                this.$store.commit('setAdd', 'MenuSwitch');
                // 先暂停所有音频播放
                let audios = document.getElementsByTagName('audio');
                [...audios].forEach((item) => {
                    item.pause()
                })
                this.RecordeItem.MediaInPlay = false
                // 检测是否有麦克风
                let checkInfo = JSON.parse( sessionStorage.checkInfo ) 
                if( !!!checkInfo.audioInput){
                    this.$message.warning("无音频输入设备!");
                    return
                }

                this.waveIndex = 0;
                this.recordTime = (parseInt(this.BindRole.recordDownTime) || 35) - this.RecordeItem.time;
                console.log(this.recordTime);
                if (!!this.BindRole.recordDownTime && this.BindRole.recordDownTime > 0) {
                    this.RecordeItem.time = this.BindRole.recordDownTime;
                } else {
                    this.RecordeItem.time = 35;
                }
                this.RecordeItem.timeshow = window.ComputedTime((this.RecordeItem.time || 35));

                if (this.RecordBtn) {
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    let list = document.querySelectorAll('#Wave div');
                    for (let index = 0; index < list.length; index++) {
                        let el = list[index];
                        el.className = '';
                    }
                    this.RecordeItem.RecorderAudio = 2;
                    Recorder.stop();
                    this.UpdateAudio();
                    this.$store.commit('setitem', {
                        'name': 'RecordBtn',
                        'item': false
                    })
                } else {
                    this.$store.commit('setitem',{'name':'RecordBtn','item':true})
                    this.wave = setInterval(()=>{
                        let list = document.querySelectorAll('#Wave div');
                        if(list[this.waveIndex ].className.indexOf('active')>-1){
                            list[this.waveIndex ].className = '';
                        }else{
                            list[this.waveIndex ].className = 'active';
                        }
                        if(this.waveIndex<9){
                            this.waveIndex++;
                        }else{
                            this.waveIndex = 0;
                        }
                    },35)
                    this.RecordeItem.Count = setInterval(() => {
                        if (this.RecordeItem.time > 0) {
                            this.RecordeItem.time--;
                            this.RecordeItem.timeshow = window.ComputedTime(this.RecordeItem.time);
                        } else {
                            this.recordTime = (parseInt(this.BindRole.recordDownTime) || 35) - this.RecordeItem
                                .time;
                            console.log(this.recordTime);
                            this.RecordeItem.RecorderAudio = 2;
                            Recorder.stop();
                            this.UpdateAudio();
                            this.$store.commit('setitem', {
                                'name': 'RecordBtn',
                                'item': false
                            })
                            clearInterval(this.wave);
                            clearInterval(this.RecordeItem.Count);
                        }
                    }, 1000)
                    this.$refs.Recorder.handleBtnClick()

                    this.$store.commit('setitem', {
                        'name': 'RecordBtn',
                        'item': true
                    })
                }

            },
            async UpdateAudio() {
                this.ComplateTest = '1';
                await this.$refs.Recorder.stopVoice()
            },
            async AddAudio(url_) {
                this.recorderPlayerUrl = url_.data;
                let path = url_.data;
                // 新增录音记录
                let data = {
                    courseId: this.$store.state.common.curriculum,
                    filePath: path,
                    questionId: this.childQuestionData.id,
                    sectionId: this.Target.id,
                    recordTime: this.recordTime,
                    seatMealCourseId: sessionStorage.getItem('seatMealCourseId') || 'abc',
                    uuid: sessionStorage.getItem('uuid'),
                }
                let add_ = await this.AjaxApi.addVideo(data);
                let fo = {
                    url: path,
                    type: '2',
                    bigTitleId: this.questionData.id,
                    smallTitleId: this.childQuestionData.id,
                    standardAnswer: this.questionData.questionText,
                }
                await this.GetScore(fo);
                if (this.Pattern == '测试模式') {
                    this.T_next(this.childQuestionListIndex, this.childQuestionListAll, this.questionIndex, this
                        .questionAll);
                }
            },

            //  滑块 tooltip
            formatTooltip(val) {
                if (!!this.player) {
                    let c = parseFloat((val / 100) * this.player.duration()).toFixed(2);
                    if (c != NaN && c != 'NaN') c += 's'
                    else c = 's'
                    return c;
                } else {
                    return '';
                }
            },
            // 录音播放
            MediaPlay() {
                this.$refs.recorderPlayer.startPlayOrPause()
                this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay;
            },
            // 录音播放完成
            recorderPlayerAudioEndedOver(){
                this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay
            },
            // 音频 播放 暂停
            PlayPause(it) {
                if (this.Pattern == '测试模式') {
                    return;
                }
                if (this.ClickLimit) {
                    return false;
                }
                this.ClickLimit = true;
                let d = window.PlayerState(this.player.paused());
                if (d) {
                    if (this.RecordeItem.RecorderAudio == 1) {
                        this.RecordeItem.Media.pause();
                    }
                    if (this.RecordeItem.RecorderAudio == 2) {
                        Recorder.stop();
                    }
                    this.RecordeItem.MediaInPlay = false;
                    this.player.play();
                    this.InPlay = true;
                } else {
                    this.player.pause();
                    this.InPlay = false;
                }
                setTimeout(() => {
                    this.ClickLimit = false;
                }, 1000)

            },
            // 提示音频播放 完成
            HintPlayerComplete() {
                if(this.BindRole.haveRecordDown == 1){
                    this.Record_a();
                }
            },
            // 音频播放完成
            PlayerComplete() {
                console.log(11111111);
                let current = parseInt(sessionStorage.getItem('PlayTwice'));
                let {
                    isPlayTwice,
                    topicPlaybackInterval
                } = this.BindRole;
                if (isPlayTwice == 1 && current < 1) {
                    current++;
                    sessionStorage.setItem('PlayTwice', current);
                    let time = topicPlaybackInterval || 3;
                    time = parseInt(time) * 1000;
                    this.InPlay = true;
                    setTimeout(this.ResetTime, time);
                } else {
                    if(this.BindRole.isAutoPaly == 1 && this.AudioPlayerUrl2[0].url != 'notEnglishAudio'){
                        this.AudioPlayerUrl2[0].isPyle = true
                    }else{
                        //判断 是否有 提示音
                        if (this.BindRole.isNeedHintAudio == 1) { // 需要 提示音
                            this.HintAudioPlayerUrl = this.BindRole.hintAudio
                            this.HintAudioPlayerConfig.autoplay = true
                        } else if(this.BindRole.haveRecordDown == 1){
                            this.Record_a();
                        }
                    }
                }

            },
            PlayerComplete2(i){
                if(this.AudioPlayerUrl2[i+1]){
                    this.AudioPlayerUrl2[i+1].isPyle = true
                }else{
                    //判断 是否有 提示音
                    if (this.BindRole.isNeedHintAudio == 1) { // 需要 提示音
                        this.HintAudioPlayerUrl = this.BindRole.hintAudio
                        this.HintAudioPlayerConfig.autoplay = true
                    } else if(this.BindRole.haveRecordDown == 1){
                        this.Record_a();
                    }
                }
            },
            // 上一题
            prevQuestion() {
                this.AudioPlayerUrl2 = []
                this.$store.commit('setitem',{name:'threeActive',item:''});
                this.$store.commit('setitem',{'name':'searchActive','item':false});
                this.$store.commit('setitem',{'name':'wordsActive','item':false});
                this.questionIndex--;
                this.QuestionChange();
            },
            // 下一题
            nextQuestion() {
                this.AudioPlayerUrl2 = []
                this.$store.commit('setitem',{name:'threeActive',item:''});
                this.$store.commit('setitem',{'name':'searchActive','item':false});
                this.$store.commit('setitem',{'name':'wordsActive','item':false});
                this.questionIndex++;
                this.QuestionChange();
            },
            // 数据 改变
            async QuestionChange() {
                // 数据还原
                if (!!this.ProgressBar && !!this.ProgressBar.time) clearInterval(this.ProgressBar.time);
                if (!!this.wave) clearInterval(this.wave);
                if (!!this.RecordeItem.Count) clearInterval(this.RecordeItem.Count);
                if (!!this.player) this.player.pause();
                if (!!this.Hint) this.Hint.pause();
                this.childQuestionListIndex = 0;
                this.GetTime = false; // 获取 视频总时长
                this.audioTime = 0; // 滑块 value
                this.playertime = ''; //总时长
                this.InPlay = false; // 判断是否 播放中
                this.check = ''; // 判断是否选择 答案
                this.complate = false; // 是否提交答案
                this.answer = ''; // 答案
                this.ProgressBar = {
                    show: false,
                    width: 100,
                    time: null,
                    duration: 0,
                    timeing: 0,
                }
                this.RecordeItem = {
                    show: true,
                    time: null,
                    timeshow: '',
                    Count: null,
                    begin: false,
                    getAudio: null,
                    RecorderAudio: 0, //  判断  当前 音频为录音  或  已录音音频    0 未录音   1 已录音在线音频   2本地录制音频
                    Media: null, // 录音播放 多媒体
                    MediaInPlay: false, // 录音播放状态
                }
                this.RecordeItem.Media = document.createElement('audio');

                let questiondata = await this.GetQuestion(this.Target.questionList[this.questionIndex].oldId, this
                    .Target.sectionMain.id);
                this.questionData = questiondata.data; // 当前问题 数据

                let qIndex = sessionStorage.getItem('questionIndex') || 0;

                if (this.questionData.childQuestionList != undefined && this.questionData.childQuestionList[qIndex].englishText != undefined && this.questionData.childQuestionList[qIndex].englishText != '') {
                    this.EnglishText = this.questionData.childQuestionList[qIndex].englishText
                }
                if (this.questionData.childQuestionList != undefined && this.questionData.childQuestionList[qIndex].chineseText != undefined && this.questionData.childQuestionList[qIndex].chineseText != '') {
                    this.ChineseText = this.questionData.childQuestionList[qIndex].chineseText
                }

                //Extensions
                let ext
                if (this.questionData.childQuestionList != undefined && this.questionData.childQuestionList[qIndex].extensions != undefined && this.questionData.childQuestionList[qIndex].extensions != '') {
                    ext = this.questionData.childQuestionList[qIndex].extensions
                }

                 let arr = eval('('+ext+')');
                this.Ext = arr.filter(el=>{
                    return el.englishText
                })

                //Synonyms
                let syn
                if (this.questionData.childQuestionList != undefined && this.questionData.childQuestionList[qIndex].synonyms != undefined && this.questionData.childQuestionList[qIndex].synonyms != '') {
                    syn = this.questionData.childQuestionList[qIndex].synonyms
                }

                this.Syn=eval(syn);
                this.Syn = this.Syn.filter((item)=>{
                    return item.englishText
                })
                console.log(this.Syn, '----------------')

                this.checkFav()


                if(this.questionData.childQuestionList == null ){
                    this.childQuestionListAll = 1; // 当前问题 子题总数
                }else{
                    this.childQuestionListAll = this.questionData.childQuestionList.length; // 当前问题 子题总数
                }
                this.childQuestionData = this.questionData.childQuestionList[0];
                this.childQuestionData.title = (typeof (this.childQuestionData.title) == 'object') ? JSON.parse(this
                    .childQuestionData.title) : this.childQuestionData.title;

                if (!!this.questionData.questionText && this.questionData.questionText != 'null' && this
                    .questionData.questionText != '') {
                    this.questionData.questionText = this.questionData.questionText.replace(this.reg, '');
                    this.questionData.Texta =
                        `<span>Subject text</span><div>${this.questionData.questionText}</div>`;
                } else {
                    this.questionData.Texta = '';
                }
                this.$refs.recorderPlayer.pausePlay()
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                this.RecordeItem.MediaInPlay = false;
                if (!!record_.data && !!record_.data.filePath) {
                    // 赋值录音路径
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = 1;
                }else{
                    this.recorderPlayerUrl = null
                }

                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.childQuestionData.id);

                if (!!notes_.data && notes_.data.length > 0) {
                    this.NoteItem = notes_.data[0];
                } else {
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }
                // 赋值音频路径
                this.AudioPlayerUrl = this.questionData.childQuestionList[0].englishAudioResource
                if(this.BindRole.isAutoPaly == 1){
                    this.AudioPlayerConfig.autoplay = true
                    this.InPlay = true
                }else{
                    this.AudioPlayerConfig.autoplay = false
                }
                if(Array.isArray(this.Syn) && this.Syn.length>0){
                    this.Syn = this.Syn.filter((item)=>{
                        return item.englishText
                    })
                    this.AudioPlayerUrl2 = []
                    this.Syn.map(el=>{
                        if(el.englishAudio){
                             this.AudioPlayerUrl2.push({url:el.englishAudio,isPyle:false})
                        }else{
                            this.AudioPlayerUrl2.push({url:'notEnglishAudio',isPyle:false})
                        }
                    })
                    this.AudioPlayerUrl2.map(el=>{
                        el.isPyle = false
                        return el
                    })
                }
                this.InfoComplate = true;
            },
            On_back() {
                this.ComplateTest = 0;
                this.T_back(this.childQuestionListIndex, this.hildQuestionListAll, this.questionIndex, this.questionAll)
            },
            On_next() {
                this.ComplateTest = 0;
                if (this.$store.state.common.MaxPattern == '闯关模式') {
                    if (this.RecordeItem.RecorderAudio != 0) {
                        this.T_next(this.childQuestionListIndex, this.childQuestionListAll, this.questionIndex, this
                            .questionAll);
                    } else {
                        this.$message.warning('请先完成本题!');
                    }
                } else {
                    this.T_next(this.childQuestionListIndex, this.childQuestionListAll, this.questionIndex, this
                        .questionAll);
                }
            },

        },
        destroyed() {
            if(!!this.player){
                this.player.dispose();
            }
            if(!!this.Hint){
                this.Hint.dispose();
            }
            clearInterval(this.wave);
            clearInterval(this.RecordeItem.Count);
        },
    }
</script>

<style lang="less" scoped>
    .Soption_box{
        .option_right{
            flex:0 0 50%;
        }
        .option_left{
            flex:0 0 50%;
            padding:0 15px;
            box-sizing: border-box;
            margin: 20px 0;
            ._img {
                width: 25%;
                height: 20%;
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .content2_title {
                font-size: 20px;
                margin-bottom: 20px;
                span {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    background: #555555;
                    margin: 0 2px 2px 0;
                }
            }
        }
    }
    .Soption_box_Active{
        .option_left{
            flex:0 0 100%;
        }
        .option_right{
            flex:0 0 100%;
        }
    }

    .words{
        align-items: center;
        color: #00092a;
        box-sizing: border-box;
        font-size: 24px;
        margin-left: 15px;
        .title2 {
            font-weight: bold;
            margin-bottom: 10px;
            .divide {
                width: 0;
                height: 15px;
                border: 1px solid #D3D3D3;
                opacity: 1;
                display: inline-block;
            }
            .Favorites {
                width: 16px;
                height: 15px;
                padding-left: 22px;
                .unActive{
                    display: inline-block;
                    width: 100px;
                    height: 100px;
                    background: url('../../assets/Favorites2.png') no-repeat;
                    background-size: cover;
                    &:hover{
                        background: url('../../assets/Favorites2.png') no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
    }

    .words3{
        display: flex;
        align-items: center;
        color: #00092a;
        font-family: 方正粗黑宋简体;
        font-size: 24px;
        height: 100px;
    }

    .content2 {
        overflow-y: auto;
        padding-left: 15px;
        box-sizing: border-box;
        .content2_title {
            font-size: 20px;
            span {
                display: inline-block;
                width: 8px;
                height: 8px;
                background: #555555;
                margin: 0 2px 2px 0;
            }
        }
    }

    .wordsimg2{
        margin-top: 20px;
    }


    .words2{
        color: #00092a;
        font-size: 16px;
    }

    .button{
        width: 100px;
        height: 20px;
    }

    .gotoBtnBox{
        text-align: center;
        width: 2.5rem;
        margin: 0.65rem auto 0.09rem;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        .gotoBtn1{
            display: inline-block;
            width: 0.83rem;
            height: 0.34rem;
            background-color: #fff;
            background: url('../../assets/extLast1.png') no-repeat ;
            background-size: 100% 100%;
            cursor: pointer;
            &:hover{
                background: url('../../assets/extLast2.png') no-repeat ;
                background-size: 100% 100%;

            }
        }
        .btn-number {
            color: #525D6C;
            font-weight: bold;
        }
        .gotoBtn2{
            display: inline-block;
            width: 0.83rem;
            height: 0.34rem;
            background-color: #fff;
            background: url('../../assets/extNext2.png') no-repeat ;
            background-size: 100% 100%;
            cursor: pointer;
            &:hover{
                background: url('../../assets/extNext1.png') no-repeat ;
                background-size: 100% 100%;

            }
        }
    }
    .content_t{
        min-height: 390px;
    }

</style>
